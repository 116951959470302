<template>
  <v-card tile>
    <v-card-text class="text-center">
      <v-container class="pa-12">
        <v-row dense class="d-flex flex-row justify-center align-center">
          <v-col cols="12">
            <h2>Aprobar multiparada</h2>
            <!-- {{ item.total }}
            <br />
            {{ wallet }}
            <br />
            {{ parseFloat(this.item.total) > wallet }} -->
          </v-col>
          <v-col cols="12">
            <p class="">¿Desea aprobar la siguiente multiparada?</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :disable-sort="!false"
              :items="[item]"
              class="dataTable"
              :hide-default-footer="true"
              show-expand
              single-expand
              item-key="unique_id"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <v-row class="d-flex">
                    <v-col cols="12" md="12">
                      <v-card class="pa-4 mt-2 mb-2">
                        <div style="border-bottom: solid 1px #ef4370">
                          <span
                            class="
                              d-flex
                              flex-row
                              justify-sm-space-between
                              align-center
                            "
                          >
                            <h2>Multi Paradas</h2>
                            <v-icon>{{ icons.mdiMapMarkerPath }}</v-icon>
                          </span>
                          <br />
                        </div>
                        <v-list>
                          <v-list-item-content
                            v-for="(stop, index) in item.routes"
                            :key="stop.index"
                          >
                            <span v-if="index === 0" class="mb-6">
                              <v-list-item-title
                                class="d-flex flex-row align-center"
                              >
                                <v-img
                                  :src="
                                    require(`@/views/Multistop/assets/Stop${index}.png`)
                                  "
                                  max-width="42"
                                ></v-img>
                                {{
                                  stop.source_address
                                    .split(",")
                                    .splice(0, 2)
                                    .join(",")
                                }}
                              </v-list-item-title>
                            </span>
                            <v-list-item-title
                              class="d-flex flex-row align-center"
                            >
                              <v-img
                                :src="
                                  require(`@/views/Multistop/assets/Stop${
                                    index + 1
                                  }.png`)
                                "
                                max-width="42"
                              ></v-img>
                              {{
                                stop.destination_address
                                  .split(",")
                                  .splice(0, 2)
                                  .join(",")
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle
                              class="
                                ml-13
                                d-flex
                                flex-row
                                justify-start
                                align-center
                              "
                            >
                              <span>
                                Total parada:
                                {{ stop.estimated_fare | currency }}
                              </span>
                              <!-- <span class="ml-1"> | </span>
                              <span class="ml-1">
                                Tiempo estimado:
                                {{ Math.ceil(stop.time) }}m
                              </span>
                              <span class="ml-1"> | </span>
                              <span class="ml-1">
                                Distancia estimada:
                                {{ stop.distance }}Km
                              </span> -->
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </td>
              </template>

              <template #item.stop_quantity="{ item }">
                <span>
                  <td>
                    <v-img
                      :src="
                        require(`@/views/Multistop/assets/Stop${item.routes.length}.png`)
                      "
                    ></v-img>
                  </td>
                </span>
              </template>
              <template #item.quote_status="{ value }">
                <span v-if="value === 0">
                  <td>
                    <v-chip
                      class="v-chip-light-bg warning--text"
                      color="warning"
                    >
                      Cotizado
                    </v-chip>
                  </td>
                </span>
              </template>
              <template #item.total="{ value }">
                <span>
                  <td class="d-flex flex-column">
                    <span
                      :class="`${
                        total_with_prome
                          ? 'text-decoration-line-through error--text mb-2'
                          : ''
                      }`"
                    >
                      {{ value | currency }}
                    </span>
                    <span v-if="total_with_prome">
                      {{ total_with_prome | currency }}
                    </span>
                  </td>
                </span>
              </template>

              <template #item.start_time="{ item }">
                <span>
                  <td>
                    {{ item.start_time | moment("DD-MM-YYYY h:mm a") }}
                  </td>
                </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <br />
        <ValidationObserver v-slot="{ invalid }">
          <v-form
            spellcheck="false"
            class="d-flex flex-column justify-space-between"
          >
            <!-- :style="$vuetify.breakpoint.mdAndUp && 'min-height: 44rem'" -->
            <v-row>
              <v-col cols="6">
                <ValidationProvider
                  name="Tipo de usuario"
                  rules="required"
                  v-slot="{ errors }"
                  slim
                >
                  <v-select
                    :items="approveMultiStopForm.userTypeOptions"
                    label="Tipo de usuario"
                    outlined
                    dense
                    v-model="approveMultiStopForm.userType"
                    :error-messages="errors[0] && ['Campo obligatorio']"
                  >
                    <template v-slot:item="{ item }">
                      <div>
                        {{ item }}
                      </div>
                    </template>
                    <template v-slot:selection="{ item }">
                      <div>
                        {{ item }}
                      </div>
                    </template>
                  </v-select>
                </ValidationProvider>
                <v-row
              v-if="
                approveMultiStopForm.userType === 'Corporativo' &&
                approveMultiStopForm.userID
              "
              class="mt-0"
            >
              <v-col cols="12">
                <ValidationProvider
                  :name="promoCodeMsg"
                  rules="max:500"
                  v-slot="{ errors }"
                  slim
                >
                  <v-text-field
                    v-model="approveMultiStopForm.promo_code"
                    outlined
                    dense
                    :label="promoCodeMsg"
                    :placeholder="addPromoCodeMsg"
                    :error-messages="errors[0]"
                    maxlength="60"
                    :readonly="isPromoCodeValid || loading_promo"
                    @keyup.enter.prevent.stop="handlePromoCodeClick"
                    class="small-text-field"
                  >
                    <template v-slot:append>
                      <div
                        class="
                          d-flex
                          align-center
                          cursor-pointer
                          pb-2
                          small-append
                        "
                      >
                        <v-btn icon small @click="handlePromoCodeClick">
                          <v-progress-circular
                            v-if="loading_promo"
                            indeterminate
                            size="20"
                          />
                          <v-icon v-else small>
                            {{
                              isPromoCodeValid
                                ? icons.mdiCloseCircle
                                : icons.mdiPlus
                            }}
                          </v-icon>
                        </v-btn>
                        <span
                          v-if="!loading_promo"
                          @click="handlePromoCodeClick"
                          class="small-span"
                        >
                          {{ isPromoCodeValid ? removeMsg : addMsg }}
                        </span>
                      </div>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
              </v-col>
              <v-col cols="6">
                <span v-if="approveMultiStopForm.userType === 'Corporativo'">
                  <ValidationProvider
                    name="Nombre del usuario"
                    rules="required"
                    v-slot="{ errors }"
                    v-if="approveMultiStopForm.userType === 'Corporativo'"
                    slim
                  >
                    <v-autocomplete
                      v-if="approveMultiStopForm.userType === 'Corporativo'"
                      v-model="approveMultiStopForm.userID"
                      label="Nombre del usuario"
                      :items="approveMultiStopForm.corporateUsersSearch"
                      outlined
                      :error-messages="errors[0] && ['Campo obligatorio']"
                      item-value="_id"
                      :menu-props="{ contentClass: 'list-style' }"
                      :filter="handleFilterAutocompleteCorporateUsers"
                      dense
                      @click="approveMultiStopForm.userID = null"
                    >
                      <template v-slot:selection="{ item }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <!-- color="primary"
                              small
                              outlined -->
                            <v-avatar color="success" size="25" class="mr-1">
                              <span class="d-flex align-center justify-center">
                                <v-img
                                  :src="`${bucketUrl}${item.picture}`"
                                  :alt="item.initials"
                                  width="25"
                                  v-if="item.picture"
                                >
                                  <template v-slot:placeholder>
                                    <v-row
                                      class="fill-height ma-0"
                                      align="center"
                                      justify="center"
                                    >
                                      <v-progress-circular
                                        indeterminate
                                        color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row> </template
                                ></v-img>
                                <span
                                  v-else
                                  class="color-blue-text text-caption"
                                >
                                  {{ item.initials }}
                                </span>
                              </span>
                            </v-avatar>
                            <span v-bind="attrs" v-on="on">
                              <span class="">
                                {{ item.fullName }}
                              </span>
                            </span>
                          </template>
                          <span class="d-flex flex-column">
                            <span class="d-flex flex-row align-center">
                              <span class="ml-1">
                                {{ item.phone }}
                              </span>
                              <v-icon
                                x-small
                                class="ml-1"
                                style="color: #ffffff !important"
                              >
                                {{ icons.mdiPhoneOutline }}
                              </v-icon>
                              <span class="ml-1">|</span>
                              <span class="ml-1">
                                {{ item.email }}
                              </span>
                              <v-icon
                                x-small
                                class="ml-1"
                                style="color: #ffffff !important"
                              >
                                {{ icons.mdiEmailOutline }}
                              </v-icon>
                            </span>
                            <span class="mt-1">
                              <span class="ml-1">
                                <span class="font-weight-bold">
                                  {{ "Saldo" }}:
                                </span>
                                {{ item.wallet | currency }}
                              </span>
                              <span class="ml-1">|</span>
                              <span class="ml-1">
                                <span class="font-weight-bold">
                                  {{ "Sobregiro" }}:
                                </span>
                                {{ item.overdraft ? "Sí" : "No" }}
                              </span>
                            </span>
                          </span>
                        </v-tooltip>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="#3BD4AE" size="45">
                          <span v-if="item.picture">
                            <v-img
                              :src="`${bucketUrl}${item.picture}`"
                              :alt="item.initials"
                              width="45"
                            >
                              <template v-slot:placeholder>
                                <v-row
                                  class="fill-height ma-0"
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="grey lighten-5"
                                  ></v-progress-circular>
                                </v-row> </template
                            ></v-img>
                          </span>
                          <span v-else>
                            {{ item.initials }}
                          </span>
                        </v-list-item-avatar>
                        <br />
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.fullName }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="d-flex flex-column">
                              <span>
                                <span>
                                  {{ item.phone }}
                                </span>
                                <v-icon x-small class="ml-1">
                                  {{ icons.mdiPhoneOutline }}
                                </v-icon>
                                <span class="ml-1">|</span>
                                <span class="ml-1">
                                  {{ item.email }}
                                </span>
                                <v-icon x-small class="ml-1">
                                  {{ icons.mdiEmailOutline }}
                                </v-icon>
                              </span>
                              <span class="mt-1">
                                <span class="ml-1">
                                  <span class="font-weight-bold">
                                    {{ "Saldo" }}:
                                  </span>
                                  {{ item.wallet | currency }}
                                </span>
                                <span class="ml-1">|</span>
                                <span class="ml-1">
                                  <span class="font-weight-bold">
                                    {{ "Sobregiro" }}:
                                  </span>
                                  {{ item.overdraft ? "Sí" : "No" }}
                                </span>
                              </span>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                      <template v-slot:append-outer>
                        <div>
                          <v-tooltip top :max-width="200">
                            <template #activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on">
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span>
                              {{ "Este campo es autocompletado" }}
                              <br />
                              {{ "Puedes buscar usuario corporativos por:" }}
                              <ul class="mt-2">
                                <li>
                                  {{ "Nombre completo" }}
                                </li>
                                <li>
                                  {{ "Correo electrónico" }}
                                </li>
                                <li>
                                  {{ "Telefóno" }}
                                </li>
                              </ul>
                            </span>
                          </v-tooltip>
                        </div>
                      </template>
                    </v-autocomplete>
                  </ValidationProvider>
                  <v-switch
                    v-model="approveMultiStopForm.isCorporateWallet"
                    class="pt-1 pl-2"
                    v-if="
                      approveMultiStopForm.userType === 'Corporativo' &&
                      approveMultiStopForm.userID
                    "
                  >
                    <template v-slot:label>
                      <span class="text-caption">
                        <span v-if="!approveMultiStopForm.isCorporateWallet">
                          Usar <u>saldo del usuario</u> para el viaje
                        </span>
                        <span v-else>
                          Usar <u>balance corporativo</u> para el viaje
                        </span>
                        <span>
                          <v-tooltip top :max-width="300">
                            <template #activator="{ on, attrs }">
                              <v-icon
                                small
                                v-bind="attrs"
                                v-on="on"
                                class="color-gray-text"
                                :style="handleColorClose"
                              >
                                {{ icons.mdiInformationOutline }}
                              </v-icon>
                            </template>
                            <span v-if="approveMultiStopForm.isCorporateWallet">
                              Al momento de crear el viaje para el usuario
                              corporativo
                              <b>{{ computedCurrentCorporateUser.fullName }}</b>
                              , el costo del mismo será debitado del
                              <u>balance corporativo</u> ({{
                                wallet | currency
                              }}), no del <u>saldo</u> ({{
                                computedCurrentCorporateUser.wallet | currency
                              }})
                              <span
                                v-if="computedCurrentCorporateUser.overdraft"
                              >
                                ni <u>sobregiro</u>
                              </span>
                              de
                              <b>{{ computedCurrentCorporateUser.fullName }}</b>
                            </span>
                            <span v-else>
                              Al momento de crear el viaje para el usuario
                              corporativo
                              <b>{{ computedCurrentCorporateUser.fullName }}</b
                              >, el costo del mismo será debitado de su
                              <u>saldo</u> ({{
                                computedCurrentCorporateUser.wallet | currency
                              }})
                              <span
                                v-if="computedCurrentCorporateUser.overdraft"
                              >
                                o de su <u>sobregiro</u>
                              </span>
                            </span>
                          </v-tooltip>
                        </span>
                      </span>
                    </template>
                  </v-switch>
                </span>
                <span
                  v-else-if="approveMultiStopForm.userType === 'No corporativo'"
                >
                  <v-row dense>
                    <v-col cols="5">
                      <ValidationProvider
                        name="Operadora"
                        rules="required"
                        v-slot="{ errors }"
                        v-if="
                          approveMultiStopForm.userType === 'No corporativo'
                        "
                        slim
                      >
                        <v-select
                          :items="approveMultiStopForm.phonePrefixList"
                          label="Operadora"
                          outlined
                          dense
                          v-model="approveMultiStopForm.phonePrefix"
                          :error-messages="errors[0] && ['Campo obligatorio']"
                          v-if="
                            approveMultiStopForm.userType === 'No corporativo'
                          "
                          :disabled="loadingUserSearch"
                        >
                        </v-select>
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="7">
                      <ValidationProvider
                        name="Teléfono"
                        rules="required|min:7|max:7"
                        v-slot="{ errors }"
                        v-if="
                          approveMultiStopForm.userType === 'No corporativo'
                        "
                        slim
                      >
                        <PhoneInput
                          label="Teléfono"
                          v-model="approveMultiStopForm.phone"
                          dense
                          outlined
                          :long="false"
                          :error-messages="errors[0] && ['Campo obligatorio']"
                          v-if="
                            approveMultiStopForm.userType === 'No corporativo'
                          "
                          :disabled="loadingUserSearch"
                          :loading="loadingUserSearch"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                </span>
              </v-col>
            </v-row>
            <v-row v-if="approveMultiStopForm.userType === 'No corporativo'">
              <v-col cols="4">
                <ValidationProvider
                  name="Nombre"
                  rules="required|min:2|alpha_spaces"
                  v-slot="{ errors }"
                  v-if="approveMultiStopForm.userType === 'No corporativo'"
                  slim
                >
                  <!-- autocomplete="new-password" para evitar sugerencias de chrome -->
                  <v-text-field
                    v-if="approveMultiStopForm.userType === 'No corporativo'"
                    v-model="approveMultiStopForm.userFirstName"
                    label="Nombre"
                    outlined
                    dense
                    autocomplete="new-password"
                    :error-messages="errors[0] && ['Campo obligatorio']"
                    :disabled="notCorporateDisabled"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  name="Apellido"
                  rules="required|min:2|alpha_spaces"
                  v-slot="{ errors }"
                  v-if="approveMultiStopForm.userType === 'No corporativo'"
                  slim
                >
                  <v-text-field
                    v-model="approveMultiStopForm.userLastName"
                    v-if="approveMultiStopForm.userType === 'No corporativo'"
                    label="Apellido"
                    outlined
                    dense
                    autocomplete="new-password"
                    :error-messages="errors[0] && ['Campo obligatorio']"
                    :disabled="notCorporateDisabled"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="4">
                <ValidationProvider
                  name="Correo electrónico"
                  rules="required|email|max:100"
                  v-slot="{ errors }"
                  :debounce="450"
                  slim
                >
                  <!-- :detectInput="false" -->
                  <v-text-field
                    v-model="approveMultiStopForm.email"
                    outlined
                    dense
                    label="Correo electrónico"
                    placeholder="Correo electrónico"
                    hide-details="auto"
                    class="mb-6"
                    autocapitalize="none"
                    autocomplete="new-password"
                    :error-messages="errors[0] && ['Campo obligatorio']"
                    :disabled="notCorporateDisabledEmail"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row
              v-if="
                approveMultiStopForm.userType === 'Corporativo' &&
                approveMultiStopForm.userID
              "
              class="color-red-text"
            >
              <v-col cols="12">
                <span
                  class="font-weight-bold"
                  v-if="computedDisabledCreateTripNote"
                  >Nota:
                </span>
                <span
                  v-if="computedDisabledCreateTripNote"
                  v-html="computedDisabledCreateTripNote"
                >
                </span>
              </v-col>
            </v-row>
            <v-row
              v-else-if="approveMultiStopForm.userType === 'No corporativo'"
              class="color-red-text"
            >
              <v-col cols="12">
                <span
                  class="font-weight-bold"
                  v-if="computedDisabledCreateTripNote"
                  >Nota:
                </span>
                <span
                  v-if="computedDisabledCreateTripNote"
                  v-html="computedDisabledCreateTripNote"
                >
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="d-flex flex-row justify-end">
                <v-btn color="primary" outlined @click="handleCancelModal()">
                  Salir
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex flex-row justify-start">
                <v-btn
                  color="primary"
                  :disabled="invalid || computedDisabledCreateTrip"
                  @click="handleCreate()"
                >
                  Aprobar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMapMarkerPath } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import {
  mdiWindowClose,
  mdiMapMarker,
  mdiCalendar,
  mdiClock,
  mdiPhoneOutline,
  mdiEmailOutline,
  mdiAccount,
  mdiBagSuitcase,
  mdiRefresh,
  mdiInformationOutline,
  mdiPlus,
  mdiCloseCircle,
} from "@mdi/js";
import PhoneInput from "@/components/PhoneInput";

export default {
  components: {
    PhoneInput,
  },
  props: {
    item: {
      type: Object,
    },
    promoCodeSuccessMsg: {
      type: String,
      default: "Promo code applied successfully",
    },
    promoCodeErrorMsg: {
      type: String,
      default: "The promo code has expired or is invalid",
    },
    errorGettingEstimatedFareWithPromoCode: {
      type: String,
      default: "Error getting estimated fare with promo code",
    },
    removeMsg: {
      type: String,
      default: "Quitar",
    },
    addMsg: {
      type: String,
      default: "Agregar",
    },
    promoCodeMsg: {
      type: String,
      default: "Cupón promocional",
    },
    addPromoCodeMsg: {
      type: String,
      default: "Agrega un cupón",
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarkerPath,
        mdiWindowClose,
        mdiMapMarker,
        mdiCalendar,
        mdiClock,
        mdiPhoneOutline,
        mdiEmailOutline,
        mdiAccount,
        mdiBagSuitcase,
        mdiRefresh,
        mdiInformationOutline,
        mdiPlus,
        mdiCloseCircle,
      },
      headers: [
        {
          text: "ID",
          value: "unique_id",
          align: "start",
          sortable: false,
        },
        {
          text: "FECHA Y HORA",
          value: "start_time",
          align: "start",
          sortable: false,
        },
        { text: "COSTO", value: "total", align: "end", sortable: false },
        {
          text: "DIRECCION DE ORIGEN",
          value: "source_address",
          align: "start",
          sortable: false,
        },
        {
          text: "ULTIMA PARADA",
          value: "destination_address",
          align: "start",
          sortable: false,
        },
        {
          text: "PARADAS",
          value: "stop_quantity",
          align: "end",
          sortable: false,
        },
        {
          text: "STATUS",
          value: "quote_status",
          align: "center",
          sortable: false,
        },
        { text: "", value: "data-table-expand" },
      ],
      approveMultiStopForm: {
        userType: null,
        userTypeOptions: ["Corporativo", "No corporativo"],
        corporateUsersSearch: [],
        userID: null,
        isCorporateWallet: false,
        phonePrefixList: ["0412", "0414", "0424", "0416", "0426"],
        phonePrefix: null,
        phone: null,
        userFirstName: null,
        userLastName: null,
        email: null,
        promo_code: undefined,
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      overdraftUser: null,
      corporateWalletUser: null,
      loadingUserSearch: false,
      corporateUsersSearch: [],
      notCorporateDisabled: true,
      notCorporateDisabledEmail: true,
      userFoundInRideryDB: false,
      isPromoCodeValid: false,
      loading_promo: false,
      promo_code: undefined,
      token: undefined,
      total_with_prome: null,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["meNotLoading"]),

    async getCorporateUsersSearch() {
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/all_users`
        );
        if (data) {
          this.corporateUsersSearch = data;
        }
      } catch (error) {
        this.$dialog.notify.error("Error getting corporate users search");
      }
    },

    handleCancelModal() {
      this.$emit("submit", null);
    },
    async getCorporateUsers() {
      this.setLoading(true);
      try {
        const { data } = await axios.get(
          `${process.env.VUE_APP_API_URL}/users`
        );
        if (!!data) {
          this.approveMultiStopForm.corporateUsersSearch = [];
          data.user_list.forEach((user) => {
            Boolean(user.is_approved) &&
              this.approveMultiStopForm.corporateUsersSearch.push({
                fullName: `${user.first_name} ${user.last_name}`,
                _id: user._id,
                phone: user.phone,
                initials: user.first_name[0] + user.last_name[0],
                email: user.email,
                overdraft: user.is_allow_overdraft,
                wallet: user.corporate_wallet_limit,
                picture: user.picture,
                token: user.token,
              });
          });
          this.approveMultiStopForm.corporateUsersSearch.sort((a, b) => {
            return a.fullName.localeCompare(b.fullName);
          });
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    handleFilterAutocompleteCorporateUsers(_item, _queryText, _itemText) {
      const searchText = _queryText.toLowerCase();

      const fullName = _item.fullName.toLowerCase();
      const phone = _item.phone.toLowerCase();
      const email = _item.email.toLowerCase();

      return (
        fullName.indexOf(searchText) > -1 ||
        phone.indexOf(searchText) > -1 ||
        email.indexOf(searchText) > -1
      );
    },
    handleUserStatus(user, corporate_id) {
      if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "0"
      ) {
        return "Active";
      } else if (
        user.corporate_ids &&
        user.corporate_ids.length > 0 &&
        user.corporate_ids[0].corporate_id === corporate_id &&
        user.corporate_ids[0].status.toString() !== "1"
      ) {
        return "Waiting for approval";
      } else if (
        user.old_corporate_ids.toString().includes(corporate_id.toString())
      ) {
        return "Inactive";
      }
    },
    async handleApproveMultiStop() {
      this.setLoading(true);
      try {
        let params = {
          quote_status: 1,
          quote_status_reason: "",
          user_id: this.approveMultiStopForm.userID,
          is_corporate_wallet:
            this.approveMultiStopForm.userType === "Corporativo" &&
            this.approveMultiStopForm.isCorporateWallet,
          promo_id: this.promo_id,
        };
        const { data } = await axios.put(
          `${process.env.VUE_APP_API_URL}/quotes/${this.item._id}/update_status`,
          params
        );
        if (!!data && data.success) {
          this.$dialog.notify.success(
            "Cotización de multiparada aprobada con éxito"
          );
          this.$emit("submit", true);
        } else {
          throw new Error("Error in /quotes/id/update_status");
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async handleCreate() {
      this.setLoading(true);

      try {
        if (this.approveMultiStopForm.userType === "Corporativo") {
          const check = await this.handleCheck(
            this.approveMultiStopForm.userID
          );
          if (check.success) {
            await this.handleApproveMultiStop();
          } else {
            throw new Error("Error en check user");
          }
        }
        if (this.approveMultiStopForm.userType === "No corporativo") {
          let check = await this.handleCheck();
          if (check.success) {
            this.approveMultiStopForm.userID = check.user._id;
            await this.handleApproveMultiStop();
          } else {
            throw new Error("Error en check user");
          }
        }
      } catch (error) {
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    async handleCheck(id) {
      //corporate
      this.setLoading(true);
      try {
        let params = {};
        if (id) {
          params["user_id"] = id;
        }
        if (!id) {
          params = {
            user_id: "",
            first_name: this.approveMultiStopForm.userFirstName,
            last_name: this.approveMultiStopForm.userLastName,
            email: this.approveMultiStopForm.email,
            is_new_user: this.userFoundInRideryDB ? 0 : 1,
            phone:
              this.approveMultiStopForm.phonePrefix.substring(1) +
              this.approveMultiStopForm.phone,
            country_phone_code: this.countryPhoneCode,
            city: "Caracas", //ajustar con respuesta de service type
            country: this.countryName,
          };
        }
        //validamos usuario
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/checkuser_corporate`,
          params
        );
        return data;
      } catch (error) {
        this.$dialog.notify.error("error check");
      }
      this.setLoading(false);
    },
    capitalizeFirstLetter: (str) => {
      return str ? str[0].toUpperCase() + str.slice(1) : "";
    },

    async validatePromoCode(event) {
      try {
        if (event) event.preventDefault();
        this.loading_promo = true;
        const response = await axios.post(
          `${process.env.VUE_APP_STAGING}/apply_promo_code`,
          {
            promocode: this.approveMultiStopForm.promo_code,
            user_id: this.approveMultiStopForm.userID,
            token: this.token,
            city_id: this.item.serviceType.cityid,
            country_id: this.item.serviceType.countryid,
            service_type_id: this.item.service_type_id,
            pickup_latitude: this.item.routes[0].p_latitude,
            pickup_longitude: this.item.routes[0].p_longitude,
            destination_latitude:
              this.item.routes[this.item.routes.length - 1].d_latitude,
            destination_longitude:
              this.item.routes[this.item.routes.length - 1].d_longitude,
            is_promo_code_preapplied: false,
          }
        );
        if (response?.data?.success) {
          const SERVER_TIMEZONE = "UTC";
          const res_estimated = await axios.post(
            `${process.env.VUE_APP_API_URL}/fare_estimates`,
            {
              service_type_id: this.item.service_type_id,
              time: this.$moment(new Date(this.item.start_time)).toISOString(),
              timezone: SERVER_TIMEZONE,
              routes: this.item.routes.map((route) => ({
                dest_latitude: route.d_latitude,
                dest_longitude: route.d_longitude,
                distance: parseFloat(route.distance) * 1000, // convert to meters
                duration: parseFloat(route.time) * 60, // convert to seconds
                latitude: route.p_latitude,
                longitude: route.p_longitude,
                source_address: route.source_address,
                destination_address: route.destination_address,
              })),
              promo_id: response.data.promo_id,
            }
          );
          if (res_estimated.data) {
            this.total_with_prome =
              "$" +
              res_estimated.data.routes.reduce(
                (accumulator, object) => accumulator + object["estimated_fare"],
                0
              );
            this.isPromoCodeValid = true;
            this.promo_id = response.data.promo_id;
            this.$dialog.notify.success(this.promoCodeSuccessMsg);
          } else {
            this.$dialog.notify.error(
              this.errorGettingEstimatedFareWithPromoCode
            );
          }
        } else {
          this.$dialog.notify.error(this.promoCodeErrorMsg);
        }
      } finally {
        this.loading_promo = false;
      }
    },
    resetPromoCode() {
      this.total_with_prome = undefined;
      this.isPromoCodeValid = false;
      this.promo_id = null;
      this.approveMultiStopForm.promo_code = "";
    },
    handlePromoCodeClick() {
      if (this.isPromoCodeValid) {
        this.resetPromoCode();
      } else if (!this.loading_promo) {
        this.validatePromoCode();
      }
    },
  },
  computed: {
    ...mapState("auth", ["wallet"]),
    ...mapState("auth", [
      "countryName",
      "countryPhoneCode",
      "_id",
      "pre_time",
      "wallet",
      "unique_id",
    ]),
    computedCurrentCorporateUser() {
      return this.approveMultiStopForm.corporateUsersSearch.find(
        (user) => user._id === this.approveMultiStopForm.userID
      );
    },
    handleColorClose() {
      return this.$vuetify.theme.dark
        ? "color: #ffffff !important;"
        : "color: #3a3541 !important;";
    },
    computedDisabledCreateTrip() {
      if (true) {
        if (
          this.approveMultiStopForm.userType === "Corporativo" &&
          !this.approveMultiStopForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            !this.overdraftUser
          ) {
            return true;
          }
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            this.overdraftUser &&
            parseFloat(this.item.total) > this.wallet
          ) {
            return true;
          }
        } else {
          if (parseFloat(this.item.total) > this.wallet) {
            return true;
          }
        }
        return false;
      }
    },
    computedDisabledCreateTripNote() {
      if (true) {
        if (
          this.approveMultiStopForm.userType === "Corporativo" &&
          !this.approveMultiStopForm.isCorporateWallet
        ) {
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            !this.overdraftUser
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}) <span class="font-weight-bold text-decoration-underline">ni</span> posee sobregiro`;
          }
          if (
            this.corporateWalletUser < parseFloat(this.item.total) &&
            this.overdraftUser &&
            parseFloat(this.item.total) > this.wallet
          ) {
            return `el usuario <span class="font-weight-bold text-decoration-underline">no</span> posee saldo corporativo suficiente ($${parseFloat(
              this.corporateWalletUser
            ).toFixed(
              2
            )}), posee sobregiro, pero el panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje ($${
              this.wallet
            })`;
          }
        } else {
          if (parseFloat(this.item.total) > this.wallet) {
            console.log("da");
            return (
              `El panel corporativo <span class="font-weight-bold text-decoration-underline">no</span> dispone de suficiente balance para crear el viaje` +
              " " +
              `($${this.wallet})`
            );
          }
        }
        return false;
      }
    },
    computedPhoneNotCorporate() {
      return (
        this.approveMultiStopForm.phonePrefix &&
        this.approveMultiStopForm.phone &&
        this.approveMultiStopForm.phonePrefix.slice(1) +
          this.approveMultiStopForm.phone
      );
    },
  },
  created() {
    this.meNotLoading();
    this.getCorporateUsersSearch();
    this.getCorporateUsers();
  },
  watch: {
    "approveMultiStopForm.userID": function () {
      if (this.approveMultiStopForm.userType === "Corporativo") {
        const user = this.approveMultiStopForm.corporateUsersSearch.find(
          (u) => u._id === this.approveMultiStopForm.userID
        );
        this.overdraftUser = user.overdraft;
        this.corporateWalletUser = user.wallet;
        this.token = user.token;
      }
    },
    computedPhoneNotCorporate: async function (_newVal, _oldVal) {
      if (
        this.computedPhoneNotCorporate &&
        this.computedPhoneNotCorporate.length === 10
      ) {
        try {
          this.loadingUserSearch = true;
          let params = {
            phone:
              this.approveMultiStopForm.phonePrefix.slice(1) +
              this.approveMultiStopForm.phone,
          };
          const { data } = await axios.get(
            `${process.env.VUE_APP_API_URL}/get_user_details`,
            { params: params }
          );
          console.log(data);
          //si el usuario existe en la base de datos de Ridery
          if (data.success) {
            this.$dialog.notify.success(
              "Usuario encontrado en la base de datos de Ridery"
            );
            //Se verifica que el usuario no este en la nomina del panel
            let found = this.corporateUsersSearch.users.find((user) => {
              return (
                user.phone ===
                this.approveMultiStopForm.phonePrefix.slice(1) +
                  this.approveMultiStopForm.phone
              );
            });
            // Si existe en la nomina del corporativo
            if (found) {
              let status = this.handleUserStatus(
                found,
                this.corporateUsersSearch.corporate_id
              );
              //si esta activo se devuelve a corporate users en el formulario
              if (status === "Active") {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.approveMultiStopForm.phonePrefix}-${this.approveMultiStopForm.phone} se encuentra activo en el panel, por favor crear viaje como corporativo`
                );
                this.approveMultiStopForm.userType = "Corporativo";
                this.approveMultiStopForm.phone = "";
                this.approveMultiStopForm.phonePrefix = "";
                this.approveMultiStopForm.userID = "";
                this.notCorporateDisabled = true;
              }
              if (status === "Waiting for approval") {
                this.$dialog.notify.warning(
                  `El usuario correspondiente al número de teléfono ${this.approveMultiStopForm.phonePrefix}-${this.approveMultiStopForm.phone} se encuentra esperando aprobación en el panel, por favor sugerir al usuario aceptar invitación al panel corporativo desde la app móvil de Ridery`
                );
                //Si existe en la nomina esperando apobacion, hay que proteger los campos para evitar duplicados, se devuelve a users
                this.notCorporateDisabled = true;
                // this.$router.push({
                //   name: "users",
                //   query: { waiting_approval_trip: 1 },
                // });
                window.location.href =
                  "/corporate_users?waiting_approval_trip=1";
              }
              if (status === "Inactive") {
                this.$dialog.notify.warning(
                  "Usuario se encuentra inactivo en el panel"
                );
                this.notCorporateDisabled = true;
                const { user } = data;
                this.approveMultiStopForm.userFirstName = user.first_name;
                this.approveMultiStopForm.userLastName = user.last_name;
                if (user.email) {
                  this.approveMultiStopForm.email = user.email;
                  this.notCorporateDisabledEmail = true;
                } else {
                  this.approveMultiStopForm.email = "";
                  this.notCorporateDisabledEmail = false;
                }
              }
            }
            //si no esta en la nomina, pero si esta en la base de datos, se llenan los campos con los datos del user y se inhabilita la edicion
            if (!found) {
              this.$dialog.notify.success(
                "Usuario encontrado en base de datos rellenando campos"
              );
              this.notCorporateDisabled = true;
              const { user } = data;
              this.approveMultiStopForm.userFirstName = user.first_name;
              this.approveMultiStopForm.userLastName = user.last_name;
              if (user.email) {
                this.approveMultiStopForm.email = user.email;
                this.notCorporateDisabledEmail = true;
              } else {
                this.approveMultiStopForm.email = "";
                this.notCorporateDisabledEmail = false;
              }
              this.userFoundInRideryDB = true;
            }
          }
          //usuario no encontrado en la base de datos de ridery
          if (!data.success) {
            console.log("user not found");
            this.approveMultiStopForm.email = "";
            this.approveMultiStopForm.userFirstName = "";
            this.approveMultiStopForm.userLastName = "";
            this.notCorporateDisabled = false;
            this.notCorporateDisabledEmail = false;
            this.userFoundInRideryDB = false;
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loadingUserSearch = false;
        }
      }
    },
    "approveMultiStopForm.userFirstName": function (newVal) {
      if (newVal) {
        this.approveMultiStopForm.userFirstName =
          this.capitalizeFirstLetter(newVal);
      }
    },
    "approveMultiStopForm.userLastName": function (newVal) {
      if (newVal) {
        this.approveMultiStopForm.userLastName =
          this.capitalizeFirstLetter(newVal);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 100vh

a
  text-decoration: none

// .custom-margin
  // margin-top: -10px !important

// .test.v-btn:not(.v-btn--round).v-size--x-large
//   height: 55.22px !important

.custom-width
  width: 46%

.custom-margin-top
  margin-top: -10px
</style>

<style scoped>
.small-text-field {
  padding: 0;
  margin: 0;
}

.small-append {
  padding-bottom: 0 !important;
}

.small-span {
  font-size: 12px;
}

.v-btn.small {
  height: 24px;
  width: 24px;
}

.v-icon.small {
  font-size: 18px;
}
</style>
